import {bootstrapApplication} from '@angular/platform-browser';
import {AppComponent} from '@scpc/app.component';
import {config} from '@scpc/app.config';
import {enableProdMode, inject, mergeApplicationConfig} from '@angular/core';
import {getHost} from '@scpc/utils/host.utils';
import {environment} from '@scp-env/environment';
import {DOCUMENT} from "@angular/common";
import {HOST, USER_AGENT} from "./tokens";

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, mergeApplicationConfig(config, {
  providers: [
    {provide: HOST, useValue: getHost(window)},
    {
      provide: USER_AGENT,
      useFactory: () => {
        const document: Document = inject(DOCUMENT);
        return document.defaultView.navigator.userAgent;
      },
    },
  ],
})).catch((err) => console.error(err));
