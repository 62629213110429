import {
  APP_ID,
  ApplicationConfig,
  importProvidersFrom,
  inject,
  provideAppInitializer,
  provideExperimentalZonelessChangeDetection,
} from '@angular/core';
import {BrowserCookiesModule} from '@scpc/modules/cookies/browser/browser-cookies.module';
import {
  AUTH_SERVICE,
  AuthModule,
  PROTECTED_FALLBACK_PAGE_URI,
  PUBLIC_FALLBACK_PAGE_URI,
} from '@scpc/modules/auth/common';
import {
  MissingTranslationHandler,
  MissingTranslationHandlerParams,
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import {HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withFetch} from '@angular/common/http';
import {TourTippyModule} from '@scpc/modules/common/tour/tippy-tour.module';
import {LoadingBarHttpClientModule} from '@ngx-loading-bar/http-client';
import {LoadingBarRouterModule} from '@ngx-loading-bar/router';
import {provideServiceWorker} from '@angular/service-worker';
import {environment} from '@scp-env/environment';
import {provideRouter, withInMemoryScrolling} from '@angular/router';
import {ROUTES} from '@scpc/app.routing';
import {AuthenticationService} from '@scpc/modules/common/services';
import {SwInterceptor} from '@scpc/modules/common/interceptors/sw.interceptor';
import {MAT_RIPPLE_GLOBAL_OPTIONS} from '@angular/material/core';
import {MultiTranslateHttpLoader} from '@scpc/modules/common/services/multi-http-loader';
import {provideClientHydration, withEventReplay, withHttpTransferCacheOptions} from '@angular/platform-browser';
import {IMAGE_CONFIG} from '@angular/common';
import {provideAnimationsAsync} from "@angular/platform-browser/animations/async";

/* istanbul ignore next */
export class ScpMissingTranslationHandler implements MissingTranslationHandler {

  public handle(params: MissingTranslationHandlerParams) {
    return params.key;
  }

}

export const config: ApplicationConfig = {
  providers: [
    provideAnimationsAsync(),
    provideHttpClient(withFetch()),
    provideServiceWorker('ngsw-worker.js', {enabled: environment.production}),
    provideRouter(ROUTES,
      withInMemoryScrolling({
        anchorScrolling: 'enabled',
        scrollPositionRestoration: 'enabled',
      }),
    ),
    provideExperimentalZonelessChangeDetection(),
    provideClientHydration(
      withEventReplay(),
      withHttpTransferCacheOptions({
        includeRequestsWithAuthHeaders: true
      }),
    ),
    importProvidersFrom(
      BrowserCookiesModule.forRoot({path: '/', secure: true}),
      AuthModule,
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: /* istanbul ignore next */ () => new MultiTranslateHttpLoader(),
          deps: [HttpClient],
        },
        missingTranslationHandler: {
          provide: MissingTranslationHandler,
          useClass: ScpMissingTranslationHandler,
        },
      }),
      TourTippyModule.forRoot(),
      LoadingBarHttpClientModule,
      LoadingBarRouterModule,
    ),
    AuthenticationService,
    {provide: APP_ID, useValue: 'scp-app'},
    {provide: PROTECTED_FALLBACK_PAGE_URI, useValue: '/'},
    {provide: PUBLIC_FALLBACK_PAGE_URI, useValue: '/sign-in'},
    {provide: AUTH_SERVICE, useFactory: /* istanbul ignore next */ (auth) => auth, deps: [AuthenticationService]},
    {provide: HTTP_INTERCEPTORS, useClass: SwInterceptor, multi: true},
    provideAppInitializer(() => {
      const translate: TranslateService = inject(TranslateService);
      translate.setDefaultLang('en');
      return translate.use('en');
    }),
    {provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: {terminateOnPointerUp: false}},
    {provide: IMAGE_CONFIG, useValue: {disableImageSizeWarning: true, disableImageLazyLoadWarning: true}},
  ],
};
